import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import LogoImage from 'common/assets/image/appModern/logo-white.png';
import LogoImageAlt from 'common/assets/image/appModern/logo.png';

const NavbarPrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbarpolicy {
          logo {
            publicURL
          }
          navMenuPolicy {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  const { navMenuPolicy } = data.appModernJson.navbarpolicy;
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const scrollItems = [];

  navMenuPolicy.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="App Modern"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="App Modern"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ul className={'scrollspy__menu menu'}>
          {navMenuPolicy.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <a
                    href={menu.path}
                >
                  {menu.label}
                </a>
              </li>
          ))}
          </ul>
          {/* end of main menu */}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          {navMenuPolicy.map((menu, index) => (
            <li key={`menu_key${index}`}>
              <a
                href={menu.path}
              >
                {menu.label}
              </a>
            </li>
          ))}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default NavbarPrivacyPolicy;
