/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
} from './banner.style';
import {Icon} from "react-icons-kit";
import { shield } from 'react-icons-kit/fa/shield';

const BannerSimple = () => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content={'Polityka prywatności'}
              icon={<Icon icon={shield} size={'30px'}/>}
            />
          </Fade>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default BannerSimple;
