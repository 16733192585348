import React  from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Particles from '../Particle';
import PolicyWrapper from './policy.style';
import Fade from "react-reveal/Fade";

const PolicySection = ({
  row,
  col,
  title,
  description,
  listCircle,
  listNumber
}) => {
  return (
    <PolicyWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Fade up delay={100}>
              <Heading
                  content="1. Wstęp"
                  {...title}
              />
              <Text
                  content="Podanie danych osobowych, a także zgoda na ich przetwarzanie są całkowicie dobrowolne. Wszelkie przekazane nam dane osobowe są przetwarzane wyłącznie w zakresie i celu, na jaki Użytkownik wyraził zgodę. W każdym momencie Użytkownik ma prawo do usunięcia, bądź aktualizacji swoich danych osobowych."
                  {...description}
              />
              <br/>
              <Text
                  content="Poufność danych osobowych i ochrona prywatności naszych Użytkowników jest priorytetem przy funkcjonowaniu strony innovcode.it yms.innovcode.it. Informacje zawarte w polityce prywatności określają sposób gromadzenia, przetwarzania, wykorzystywania danych powierzonych przez Użytkownika."
                  {...description}
              />
              <Heading
                  content="2. Zasady przetwarzania danych osobowych:"
                  {...title}
              />
              <ol style={{...listNumber}}>
                <li>Zbieramy tylko niezbędne dane do działania aplikacji</li>
                <li>Nie wykorzystujemy w innych celach niż wymienione w naszej Polityce Prywatności</li>
                <li>Nie przechowujemy danych dłużej, niż to jest potrzebne</li>
                <li>Nie ujawniamy danych osobom trzecim w sytuacjach innych niż wskazane w Polityce Prywatności</li>
                <li>Użytkownicy będący płatnikami abonamentu: W celach podatkowych i rachunkowych w zakresie i przez czas zgodny z obowiązującymi przepisami</li>
              </ol>
              <Heading
                  content="3. Administrator danych osobowych"
                  {...title}
              />
              <Text
                  content="Administratorem, czyli odpowiedzialnym za przetwarzanie, oraz zapewnienie bezpieczeństwa danych osobowych Użytkowników pozyskanych w w związku z korzystaniem ze strony jest:"
                  {...description}
              />
              <p><strong>Innovcode.it Zofia Nawrotkiewicz</strong></p>
              <Text content="62-561 Ślesin" {...description} />
              <Text content="ul. Polna 16" {...description} />
              <Text content="NIP: 665-288-16-04" {...description} />
              <Text content="zofia.nawrotkiewicz@innovcode.it" {...description} />
              <br/>
              <Text
                  content="Jeśli odwiedzając stronę yms.innovcode.it, innovcode.it Użytkownik nie zdecyduje się skontaktować się z nami poprzez formularze kontaktowe dostępne na naszej stronie internetowej, wówczas nie przetwarzamy żadnych informacji na jego temat, z wyjątkiem adresu IP. Zapamiętanie adresu IP jest niezbędne ze względów technicznych związanych z administrowaniem naszymi serwerami. Adres IP Użytkownika zostanie wykorzystany przez nas również do zbierania ogólnych, statystycznych informacji (np. Lokalizacji), oraz dostosowania strony do potrzeb użytkowników. Dane te w żaden sposób nie umożliwiają identyfikacji osoby odwiedzającej stronę."
                  {...description}
              />
              <Heading
                  content="4. Przechowywanie danych osobowych:"
                  {...title}
              />
              <Text
                  content="Dane osobowe, wypełniane w formularzu udostępniania wersji demo na stronie yms.innovcode.it będą przetwarzane w celu komunikacji między Użytkownikiem a innovcode.it., na zasadach obowiązujących w regulaminie."
                  {...description}
              />
              <Text
                  content="Podanie danych tych jest niezbędne do udostępnienia wersji demo, składania reklamacji."
                  {...description}
              />
              <br/>
              <Text
                  content="Dane osobowe mogą być również przetwarzane w celu ustalenia, obrony lub dochodzenia roszczeń. Podstawą przetwarzania danych osobowych w tym celu jest nasz prawnie uzasadniony interes polegający na ochronie praw Innovcode.it w postępowaniach sądowych lub administracyjnych."
                  {...description}
              />
              <br/>
              <Text
                  content="Dane osobowe w momencie braku chęci korzystania z usług zostają usunięte po rozwiązaniu sprawy."
                  {...description}
              />
              <br/>
              <Text
                  content="W przypadku woli korzystania z naszych usług dane osobowe są przechowywane przez cały okres korzystania z naszych usług, lub do momentu otrzymania przez nas informacji iż dane mają zostać usunięte."
                  {...description}
              />
              <Heading
                  content="5. Komu możemy przekazać twoje dane osobowe"
                  {...title}
              />
              <ul style={{...listCircle}}>
                <li>Osobom upoważnionym przez nas, naszym pracownikom i współpracownikom, którzy muszą mieć dostęp do danych osobowych w celu wykonywania swoich obowiązków,</li>
                <li>Podmiotom przetwarzającym, którym powierzymy przetwarzanie danych osobowych</li>
              </ul>
              <Heading
                  content="6. Jakie prawa przysługują naszym Użytkownikom jako podmiotom danych osobowych?"
                  {...title}
              />
              <Text
                  content="Zgodnie z przepisami Ogólnego Rozporządzenia dotyczącego Ochrony Danych Osobowych (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE – (RODO), przysługują Użytkownikowi następujące prawa w związku z przetwarzaniem danych osobowych przez nas."
                  {...description}
              />
              <ul style={{...listCircle}}>
                <li>Prawo dostępu do danych</li>
                <li>Prawo żądania sprostowania danych</li>
                <li>Prawo do usunięcia danych (prawo do bycia zapomnianym)</li>
                <li>Prawo wniesienia skargi do organu nadzorczego ochrony danych osobowych, jakim jest w Polsce Prezes Urzędu Ochrony Danych Osobowych</li>
                <li>Prawo do ograniczenia przetwarzania danych</li>
                <li>Prawo sprzeciwu (sprzeciw wobec przetwarzania, sprzeciw wobec marketingu bezpośredniego, w tym profilowania, sprzeciw uzasadniony wobec przetwarzania danych w uzasadnionym interesie Administratora)</li>
              </ul>
              <Text
                  content="Dane Użytkownika są przetwarzane na podstawie zgody. Użytkownik na prawo do wycofania zgody."
                  {...description}
              />
              <Heading
                  content="7. Cookies - automatycznie pobierane dane informatyczne:"
                  {...title}
              />
              <Text
                  content="W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. Korzystanie z naszej witryny oznacza, że będą one zamieszczane w Państwa urządzeniu. W każdym momencie mogą Państwo dokonać zmiany ustawień Państwa przeglądarki. Dodatkowo, korzystanie z naszej witryny oznacza akceptację przez Państwa klauzuli przetwarzania danych osobowych udostępnionych drogą elektroniczna"
                  {...description}
              />
              <Text
                  content="Pliki cookie to niewielki plik tekstowy, który jest zapisywany na Państwa urządzeniu, przy odwiedzaniu naszej strony. Pliki te są przesyłane przez serwis internetowy do przeglądarki. Każdy plik cookie posiada anonimowy identyfikator i pozwala zidentyfikować urządzenie, w którego pamięci został zapisany przez określony czas (czas, po którym dany plik cookie zostanie usunięty). Pliki cookies są zapisywane w celu rozpoznania Twojego urządzenia, poznania Twoich preferencji, a także zbierania informacji na temat Twoich wcześniejszych działań na Portalu."
                  {...description}
              />
              <br/>
              <Text
                  content="innovcode.it dla użytkowników zarejestrowanych stosuje są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika."
                  {...description}
              />
              <br/>
              <Text
                  content="Pliki generowane bezpośrednio przez innovcode.it są zabezpieczone i nie mogą być odczytywane przez inne serwisy."
                  {...description}
              />
              <Heading
                  content="8. Jak długo przechowujemy pliki cookies?"
                  {...title}
              />
              <Text
                  content="Sesyjne pliki cookies pozostają na Twoim urządzeniu do momentu opuszczenia przez Ciebie strony lub wyłączenia oprogramowania (przeglądarki internetowej)."
                  {...description}
              />
              <Text
                  content="Stałe pliki cookies będą przechowywane na Twoim urządzeniu maksymalnie przez okres 13 miesięcy."
                  {...description}
              />
              <Heading
                  content="9. Usługodawca wykorzystuje pliki cookies"
                  {...title}
              />
              <ol style={{...listNumber}}>
                <li>Niezbędne do utrzymywania sesji użytkownika (pliki własne), uwierzytelniające</li>
                <li>Dostosowania zawartości aplikacji do uprawnień użytkownika (pliki własne)</li>
                <li>Zapewniające bezpieczeństwo danych (pliki własne)</li>
                <li>Wydajnościowe, umożliwiające zbieranie informacji o częstotliwości, sposobie korzystania z aplikacji</li>
                <li>Tworzenia statystyk (anonimowych) pozwalających optymalizowanie użyteczności serwisu (pliki zewnętrzne)</li>
                <li>Łączenia funkcji aplikacji z innymi zewnętrznymi oprogramowaniami, jeżeli wymaga tego Użytkownik główny (pliki zewnętrzne)</li>
              </ol>
              <Text
                  content="Oprogramowania służące do przeglądania stron internetowych w wielu przypadkach domyślnie dopuszcza przechowywanie plików cookies na urządzeniu użytkownika. Użytkownik w każdym momencie może zmienić ustawienia na swoim urządzeniu."
                  {...description}
              />
              <Heading
                  content="10. Narzędzia wykorzystywane na stronach innovcode.it"
                  {...title}
              />
              <strong>Google Analytics</strong>
              <small> Cookies zewnętrzne (Google Inc.)</small>
              <Text
                  content="Zbieranie ogólnych i anonimowych danych statycznych"
                  {...description}
              />
              <br/>

              <strong>Google AdWords</strong>
              <small> Cookies zewnętrzne (Google Inc.)</small>
              <Text
                  content="Prezentowanie reklam dostosowanych do preferencji Użytkownika"
                  {...description}
              />
              <br/>

              <strong>Google Tag Manager</strong>
              <small> Cookies zewnętrzne (Google Inc.)</small>
              <Text
                  content="Zbieranie ogólnych i anonimowych danych statycznych"
                  {...description}
              />
              <br/>
              <Text
                  content="Pliki cookies zewnętrzne są przechowywane przez 365 dni.
"
                  {...description}
              />
              <Heading
                  content="11. Zmiany polityki prywatności"
                  {...title}
              />
              <Text
                  content="Polityka Prywatności jest udoskonalana poprzez nowe zapisy wchodzące w najnowsze wymogi prawa. Żadna zmiana nie będzie ograniczała Państwa praw bez zgody z Państwa strony."
                  {...description}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </PolicyWrapper>
  );
};

PolicySection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  listCircle: PropTypes.object,
  listNumber: PropTypes.object,
};

PolicySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1],
  },
  title: {
    fontSize: ['22px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '15px', '15px', '20px', '25px'],
    mt: ['20px'],
    lineHeight: '1.3',
    maxWidth: ['100%'],
  },
  description: {
    fontSize: '16px',
    color: '#0f2137',
    lineHeight: '1.85',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  listCircle: {
    listStyle: 'circle',
    listStyleType: 'disc',
    color: '#0f2137'
  },
  listNumber: {
    listStyle: 'decimal',
    color: '#0f2137'
  }
};

export default PolicySection;
