import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Footer from 'containers/AppModern/Footer';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from 'components/seo';
import BannerSimple from "../containers/AppModern/BannerSimple";
import PrivacyPolicy from "../containers/AppModern/PrivacyPolicy";
import Cookie from "../containers/AppModern/Cookie";
import NavbarPrivacyPolicy from "../containers/AppModern/NavbarPrivacyPolicy";

export default function () {
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO title="Innovcode" />
                <Modal />
                <ResetCSS />
                <GlobalStyle />
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-active">
                        <NavbarPrivacyPolicy />
                    </Sticky>
                    <ContentWrapper>
                        <BannerSimple />
                        <PrivacyPolicy />
                    </ContentWrapper>
                    <Footer />
                </AppWrapper>
                <Cookie />
            </>
        </ThemeProvider>
    );
}
